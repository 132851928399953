( function( $, window, document, undefined ) {

	let $toggle = $('.js-site-nav-toggle');

	const IS_DESKTOP_MQ = '(min-width: 1024px)';

	// Add default attr
	$toggle.attr('aria-expanded', 'false');

	function open() {
		$toggle.attr('aria-expanded', 'true');
		$toggle.addClass('is-active');

		bodyScrollLock.disableBodyScroll(document.querySelector('.js-site-nav-list'), {reserveScrollBarGap: true});

		$(document).on('keydown.mobile_nav', (event) => {
			if ( 'Escape' !== event.key ) {
				return;
			}

			close();

			event.preventDefault();
			return false;
		});
	}

	function close() {
		$toggle.attr('aria-expanded', 'false');
		$toggle.removeClass('is-active');

		bodyScrollLock.enableBodyScroll(document.querySelector('.js-site-nav-list'));

		$(document).off('.mobile_nav');
	}

	function is_open() {
		return $toggle.is('[aria-expanded="true"]');
	}

	function toggle() {
		if ( is_open() ) {
			close();
		} else {
			open();
		}
	}


	$toggle.on('click', (event) => {
		toggle();

		event.preventDefault();
		return false;
	});	

	$(window).on('resize', throttle( () => {
		if ( is_open() && window.matchMedia(IS_DESKTOP_MQ).matches ) {
			close();

			let $submenus = $('.js-site-nav-list .has-children > a + .submenu');
			$submenus.css('display', '');
		}
	}, 150 ));


	// Submenus
	(function( $ ) {

		let $submenus = $('.js-site-nav-list .has-children > a');

		function open( $trigger ) {
			$trigger.attr('aria-expanded', 'true');
			$trigger.next('.submenu').slideDown(350, function() {
				$(this).attr('aria-hidden', 'false');
			});
		}

		function close( $trigger ) {
			$trigger.attr('aria-expanded', 'false');
			$trigger.next('.submenu').slideUp(350, function() {
				$(this).attr('aria-hidden', 'true');
			});
		}

		function is_open( $trigger ) {
			return $trigger.is('[aria-expanded="true"]');
		}

		function toggle( $trigger ) {
			if ( is_open($trigger) ) {
				close($trigger);
			} else {
				open($trigger);
			}
		}

		$submenus.on('click', function(event) {
			if ( window.matchMedia(IS_DESKTOP_MQ).matches ) {
				return;
			}

			toggle($(this));

			event.preventDefault();
			return false;
		});	
		
	})( jQuery );
	
} )( jQuery, window, document );
