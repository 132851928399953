// Initialise Slick slider

( function( $, window, document, undefined ) {

	// Uncomment to use SVG icon buttons
	const prevArrowHTML = `<button type="button" class="slick-prev" aria-label="Previous slide"><svg xmlns="http://www.w3.org/2000/svg" width="47.98" height="21.28" viewBox="0 0 47.98 21.28">
	<g id="arrow" transform="translate(47.98 21.28) rotate(180)">
	  <g id="Layer_1" data-name="Layer 1">
		<path id="Path_5080" data-name="Path 5080" d="M37.34,0,35.93,1.41l8.16,8.16H0v2H44.22l-8.29,8.3,1.41,1.41L47.98,10.64Z" fill="currentcolor"/>
	  </g>
	</g>
  </svg></button>`;
	const nextArrowHTML = `<button type="button" class="slick-next" aria-label="Next slide"><svg id="arrow" xmlns="http://www.w3.org/2000/svg" width="47.98" height="21.28" viewBox="0 0 47.98 21.28">
	<g id="Layer_1" data-name="Layer 1">
	  <path id="Path_5080" data-name="Path 5080" d="M37.34,0,35.93,1.41l8.16,8.16H0v2H44.22l-8.29,8.3,1.41,1.41L47.98,10.64Z" fill="currentcolor"/>
	</g>
  </svg></button>`;

	const $imageCarousel = $('.js-image-carousel');

	if ( $imageCarousel.length ) {

		$imageCarousel.slick( {
			autoplay: false,
			dots: true,
			arrows: true,
			prevArrow: prevArrowHTML,
			nextArrow: nextArrowHTML,
			infinite: true,
			adaptiveHeight: false,
			draggable: true,
			slidesToShow: 1,
			centerMode: true,
			centerPadding: '17%',
			responsive: [
				{
					breakpoint: 768,
					settings: {
						centerMode: false,
						centerPadding: 0,
						arrows: false,
						adaptiveHeight: true
					}
				}
			]
		} );

	} // /$imageCarousel.length


	const $stepsCarousel = $('.js-steps-carousel');

	if ( $stepsCarousel.length ) {

		$stepsCarousel.slick( {
			autoplay: false,
			dots: true,
			arrows: true,
			prevArrow: `<button type="button" class="slick-prev button-rounded" aria-label="Previous step"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 21.28"><path d="M10.64,21.28l1.41-1.41L3.89,11.71,2.826,10.64l.934-.93,8.29-8.3L10.64,0,0,10.64Z" fill="currentcolor"/></svg></button>`,
			nextArrow: `<button type="button" class="slick-next button-rounded"><span><span class="slick-next-text">Next Step</span> <span class="slick-next-title">Legal Contract</span></span> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.05 21.28"><path d="M1.41,0,0,1.41,8.16,9.57l1.064,1.07-.934.93L0,19.87l1.41,1.41L12.05,10.64Z" fill="currentcolor"/></svg></button>`,
			infinite: false,
			adaptiveHeight: false,
			draggable: true,
			slidesToShow: 1,
			responsive: [
				{
					breakpoint: 768,
					settings: {
						centerMode: false,
						centerPadding: 0,
						adaptiveHeight: true
					}
				}
			]
		} );

		let $slickRestart = $('.slick-arrow.slick-restart');

		$slickRestart.click(function(event) {
			$stepsCarousel.slick('slickGoTo', 0);
		});

		$stepsCarousel.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
			let $nextSlide = slick.$slides.eq(nextSlide + 1);
			let $buttonTitle = slick.$slider.find('.slick-next-title');

			if ( $nextSlide.length ) {
				// change next slide text on button
				$buttonTitle.text( $nextSlide.find('.steps-carousel__title').text() );
			}

			if ( nextSlide + 1 == slick.slideCount ) {
				$slickRestart[0].hidden = false;
			} else {
				$slickRestart[0].hidden = true;
			}
		});

	} // /$stepsCarousel.length


	const $newsCarousel = $('.js-news-carousel');

	if ( $newsCarousel.length ) {

		// Run on a specific selector with default args
		$newsCarousel.slick( {
			autoplay: false,
			dots: true,
			arrows: false,
			infinite: true,
			adaptiveHeight: true,
			draggable: true,
			slidesToShow: 1,
		} );

	} // /$newsCarousel.length


	const $statsCarousel = $('.js-stats-carousel');

	if ( $statsCarousel.length ) {

		// Run on a specific selector with default args
		$statsCarousel.slick( {
			autoplay: false,
			dots: true,
			arrows: false,
			infinite: true,
			adaptiveHeight: true,
			draggable: true,
			slidesToShow: 1,
		} );

	} // /$statsCarousel.length


	const $teamCarousel = $('.js-team-carousel');

	if ( $teamCarousel.length ) {

		// Run on a specific selector with default args
		$teamCarousel.slick( {
			autoplay: false,
			dots: true,
			arrows: false,
			infinite: false,
			adaptiveHeight: true,
			draggable: true,
			variableWidth: true,
			swipeToSlide: true
		} );

	} // /$teamCarousel.length

} )( jQuery, window, document );
