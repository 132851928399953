(function( $ ) {

	// Set up reporting for GA events
	let $emailLinks = $('a[href^="mailto:"]');
	let $phoneLinks = $('a[href^="tel:"]');

	function createFunctionWithTimeout(callback, opt_timeout) {
	    var called = false;

	    function fn() {
	        if (!called) {
	            called = true;
	            callback();
	        }
	    }
	    setTimeout(fn, opt_timeout || 1000);
	    return fn;
	}

	function getLinkHref( $link ) {
		let href = $link.attr('href');

		// remove prefix and any query params
		href = href.split(':')[1];
		href = href.split('?')[0];

		return href;
	}

	// replace calls to gtag with this to check 
	// event values and that click behaviour 
	// is preserved before sending it for real
	function gtag_debug(type, name, config) {
		console.log( type, name, config );
		config.event_callback();
	}

	$emailLinks.click(function(event) {
		let $this = $(this);

		// if this link is flagged as having been seen and event sent...
		if ( 'true' === $this.attr('data-event-sent') ) {
			// remove the flag and return early to let browser handle link
			// and prevent duplicate events being sent
			$this.removeAttr('data-event-sent');
			return;
		}

		// prevent the browser from taking the default action while event is sent
		event.preventDefault();

		let href = getLinkHref($this);	

		// sends the event to Google Analytics and
		// clicks the link again once the hit is confirmed.
		gtag('event', 'email_clicked', { 
			'href': href,
			'event_category': 'contact',
			'event_callback': createFunctionWithTimeout(function() {
				// add flag to prevent programmatic click causing infinite loop
		    	$this.attr('data-event-sent', true);

		    	// retrigger click on link so normal browser behaviour occurs
		    	$this[0].click();
		    })
		});
	});

	$phoneLinks.click(function(event) {
		let $this = $(this);

		// if this link is flagged as having been seen and event sent...
		if ( 'true' === $this.attr('data-event-sent') ) {
			// remove the flag and return early to let browser handle link
			// and prevent duplicate events being sent
			$this.removeAttr('data-event-sent');
			return;
		}

		// prevent the browser from taking the default action while event is sent
		event.preventDefault();

		let href = getLinkHref($this);

		// sends the event to Google Analytics and
		// clicks the link again once the hit is confirmed.
		gtag('event', 'phone_clicked', { 
			'href': href,
			'event_category': 'contact',
			'event_callback': createFunctionWithTimeout(function() {
				// add flag to prevent programmatic click causing infinite loop
		    	$this.attr('data-event-sent', true);

		    	// retrigger click on link so normal browser behaviour occurs
		    	$this[0].click();
		    })
		});
	});
	
})( jQuery );
