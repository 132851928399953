(function( $ ) {

	function updateSubmenuSize() {
		let $siteNav = $('.js-site-nav-list');
		$('.js-submenu-inner').css('--nav-width', `${Math.ceil($siteNav.width())}px`);
	}

	updateSubmenuSize();
	$(window).on('resize', debounce(function(event) {
		updateSubmenuSize();
	}, 150));


	// mega menu hover state
    (function( $ ) {

        let $childLinks = $('.has-children');
        const IS_DESKTOP_MQ = window.matchMedia('(min-width: 1024px)');

        if ( $childLinks.length ) {

        	$childLinks.each(function(index, node) {
        		let $link = $(node).children('a');
                let $submenu = $(node).children('.submenu');

                $link.attr('aria-expanded', 'false');
                $submenu.attr('aria-hidden', 'true');
        	});

            $childLinks.on('mouseenter.submenuController', function (event) {
                if ( !IS_DESKTOP_MQ.matches ) {
                    return;
                }

                let $link = $(this).children('a');
                let $submenu = $(this).children('.submenu');

                $link.attr('aria-expanded', 'true');
                $submenu.attr('aria-hidden', 'false');

                $('.navigation__overlay').addClass('active');
            });

            $childLinks.on('mouseleave.submenuController', function (event) {
                if ( !IS_DESKTOP_MQ.matches ) {
                    return;
                }

                let $link = $(this).children('a');
                let $submenu = $(this).children('.submenu');

                $link.attr('aria-expanded', 'false');
                $submenu.attr('aria-hidden', 'true');

                $('.navigation__overlay').removeClass('active');
            });

        }
        
    })( jQuery );

    // Nav states
    /**
    * States:
    *     Home page, at top: transparent
    *     Any page scrolled down: White background, box shadow
    *     Hover (any page): white background, no box shadow
    * 
    *     When scrolling down, hide until scrolled back up 
    */
    (function( $ ) {
        let $siteHeader = $('.site-header');
        let persistAction = '';

        function updateState( action ) {
            $siteHeader.attr('data-state', determineState(action));
        }

        function determineState( action ) {
            let state = [];
            let scrollThreshold = 100;

            if ( $(window).scrollTop() > scrollThreshold ) {
                state.push('filled');
                $siteHeader.addClass('scrolled');
            }else{
                $siteHeader.removeClass('scrolled');
            }

            if (
                $(window).scrollTop() > $(window).height() &&
                'scroll-down' === action &&
                'hover' !== persistAction
            ) {
                state.push('off-canvas');
            }

            if ( 'hover-off' === action && 'hover' === persistAction ) {
                persistAction = '';
            }

            if ( 'hover' === action || 'hover' === persistAction ) {
                state.push('hover');
                state.push('filled');

                persistAction = 'hover';
            }

            return state ? state.join(' ') : 'default';
        }

        // set intial state
        let scrollDelta = 0;
        let scrollTop = $(window).scrollTop();

        $(window).on('scroll.headerController', function(event) {
            let action = '';

            scrollDelta = $(window).scrollTop() - scrollTop;

            if ( scrollDelta > 0 ) {
                action = 'scroll-down';
            }

            scrollTop = $(window).scrollTop();
            scrollDelta = 0;

            updateState(action);
        });

        updateState();

        $(window).on('resize.headerController', debounce(function(event) {
            updateState();
        }, 150));

        $('.site-nav__list').on('mouseenter.headerController', function(event) {
            updateState('hover');
        });

        $('.site-nav__list').on('mouseleave.headerController', function(event) {
            updateState('hover-off');
        });
       
    })( jQuery );

    //scroll to team location
    $('.js-team-nav li a').on('click',  function(e) {
        var link = $(this).attr('href');
        var linkOffset = $(link).offset().top;

        var offset = 150;

        if ( linkOffset < $(window).scrollTop() ) {
            offset = 250;
        }

        $('html, body').animate(
            {
                scrollTop: linkOffset - offset
            }, 
            {
                duration: 500,
            }
        );

        e.preventDefault();
        return false;
    });

    // team page scrolling nav
    (function( $ ) {

        let $teamNav = $('.js-team-nav');
        let $siteHeader = $('.site-header');

        function updateNavTop() {
            if ( $(window).scrollTop() < $teamNav[0].offsetTop ) {
                $teamNav.css('transform', '');
                return;
            }

            if ( $siteHeader.is('[data-state~="off-canvas"]') ) {
                $teamNav.css('transform', '');
            } else {
                $teamNav.css('transform', `translateY(${$siteHeader.outerHeight(false)}px)`);
            }
        }

        if ( $teamNav.length ) {
            updateNavTop();
            $(window).on('scroll', throttle(updateNavTop, 50));
            $(window).on('resize', debounce(updateNavTop, 150));
        }
        
    })( jQuery );
	
})( jQuery );

