
(function( $ ) {

	let $popups = $('.js-popup');
	const POPUP_DELAY = 6000;
	const POPUP_THRESHOLD = $(window).height() / 3;
	const initialScrollTop = $(window).scrollTop();

	if ( !$popups.length ) {
		return;
	}


	$popups.each(function(index, node) {
		let $popup = $(node);
		let $close = $popup.find('.js-popup-close');

		let cookieName = 'popup-closed-' + $popup.data('id');
		let wasClosed = '1' == cookie(cookieName);

		// keep timeout ID for showing the popup after period of non-interactivity
		let showTimeout = null;

		function hide() {
			$popup.addClass('is-hidden');
			$popup.find('button, a').attr('aria-hidden', 'true');
		}

		function show() {
			$popup.removeClass('is-hidden');
			$popup.find('button, a').attr('aria-hidden', 'false');
		}

		// set initial state
		hide();


		if ( !wasClosed ) {
			showTimeout = setTimeout(function() { 
				show(); 
				showTimeout = null;
			}, POPUP_DELAY);

			$(window).on('scroll.popupController', throttle(function(event) {
				if ( $(window).scrollTop() >= POPUP_THRESHOLD + initialScrollTop ) {
					show();

					if ( showTimeout ) {
						clearTimeout(showTimeout);
						showTimeout = null;
					}
				}
			}, 150));
		}

		$popup.find('a, button').click(function(event) {
			// if user interacts with the CTA, hide the popup from appearing again
			cookie(cookieName, '1', 7);
		});


		$close.click(function(event) {
			hide();
			$(window).off('.popupController');

			// store closed value for 1 week
			cookie(cookieName, '1', 7);
		});
	});
	
})( jQuery );
